import request from "@/utils/request";

// StockOutRecord
export function stockOutRecordList(params) {
  return request({ url: `/stock_out_records/`, method: "get", params });
}

export function stockOutRecordCreate(data) {
  return request({ url: `/stock_out_records/`, method: "post", data });
}

export function stockOutRecordVoid(data) {
  return request({ url: `/stock_out_records/${data.id}/void/`, method: "post", data });
}

export function stockOutRecordImport(data) {
  return request({
    url: "/stock_out_records/import_data/",
    method: "post",
    data,
  });
}

export function stockOutRecordTemplate(params) {
  return request({
    url: "/stock_out_records/import_template/",
    responseType: "blob",
    method: "get",
    params,
  });
}
